import { Spinner, Viewer, Worker } from '@react-pdf-viewer/core'
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail'
import { City, Country, State } from 'country-state-city'
import { parseISO } from 'date-fns'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { IconButton, PrimaryButton, SecondaryButton } from '../../components/Button'
import DateSelector from '../../components/DateSelector'
import FileInput from '../../components/FileInput'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import NumberInput from '../../components/NumberInput'
import PageHeader from '../../components/PageHeader'
import SearchMultiselect from '../../components/SearchMultiselect'
import Close from '../../icons/Close'
import Edit from '../../icons/Edit'
import { Ubo } from '../../state/Radom'
import { RADOM_COLORS } from '../../util/Constants'
import { errorToast, pageThumbnailPlugin } from '../../util/Util'

export interface UboForm extends Ubo {
  govIdImageFile?: File
  proofOfAddressFile?: File
}

interface IProps {
  state: {
    ubos: UboForm[]
    ubo: UboForm
  }
  isSubmitting: boolean
  canSubmit: boolean
  onUpdate: (value: any) => void
  onAdd: (ubo: UboForm) => void
  onEdit: (index: number) => void
  onStopEdit: () => void
  onRemove: (index: number) => void
  onSubmit: () => void
}

const ListItem = styled.li`
  align-items: center;
  border: 1px solid ${RADOM_COLORS.GRAY8};
  border-radius: 10px;
  display: flex;
  list-style: none;
  margin: 16px 0;
  padding: 8px 16px;
  width: fit-content;
  gap: 16px;

  &:first-child {
    margin-top: 0;
  }
    
  &:last-child {
    margin-bottom: 0;
  }
`

const StickingFooter = styled.div`
  position: fixed;
  padding: 20px 40px;
  width: 100vw;
  background-color: white;
  border-top: 1px solid ${RADOM_COLORS.GRAY_DARK};
  top: calc(100vh - 80px);
  left: 0;
`

const Ubos = ({ state, isSubmitting, canSubmit, ...props }: IProps): ReactElement => {
  const proofOfAddressDocumentThumbnail = thumbnailPlugin({ renderSpinner: () => <Spinner />, thumbnailWidth: 100 })
  const { Cover } = proofOfAddressDocumentThumbnail

  const proofOfAddressPageThumbnail = pageThumbnailPlugin({
    PageThumbnail: (
      <div style={{ cursor: 'pointer', width: '100px' }} onClick={() => window.open(state.ubo.proofOfAddress)}>
        <Cover getPageIndex={() => 0} width={100} />
      </div>
    )
  })

  const [ubo, setUbo] = useState<UboForm>(state.ubo)
  const [isEditing, setIsEditing] = useState(false)

  const isValid = useMemo(() => state.ubos.length > 0, [state.ubos])

  const canSave = useMemo(() => {
    return !!ubo.firstName && !!ubo.lastName && !!ubo.birthDate &&
    !!ubo.email && !!ubo.phone &&
    !!ubo.taxIdentificationNumber && !!ubo.address.postalCode && !!ubo.address.city &&
    !!ubo.address.state && !!ubo.address.country &&
    !!ubo.relationshipEstablishedAt && (!!ubo.govIdImageFile || !!ubo.govIdImage) && !!ubo.percentageOwnership &&
    (!!ubo.proofOfAddress || !!ubo.proofOfAddressFile)
  }, [ubo])

  const countries = useMemo(() => Country
    .getAllCountries()
    .map((country) => ({
      key: country.isoCode,
      value: country.name
    })),
  [])

  const states = useMemo(() => State
    .getStatesOfCountry(ubo.address.country)
    .map((state) => ({
      key: state.isoCode,
      value: state.name
    })),
  [ubo.address.country])

  const cities = useMemo(() => City
    .getCitiesOfState(ubo.address.country, ubo.address.state)
    .map((city) => ({
      key: city.name,
      value: city.name
    })),
  [ubo.address.country, ubo.address.state])

  const selectedCountry = useMemo(
    () => countries.find((country) => country.key === ubo.address.country),
    [ubo.address.country, countries]
  )

  const selectedState = useMemo(
    () => states.find((s) => s.key === ubo.address.state),
    [ubo.address.state, states]
  )

  const selectedCity = useMemo(
    () => cities.find((city) => city.key === ubo.address.city),
    [ubo.address.city, cities]
  )

  useEffect(() => {
    setUbo(state.ubo)
  }, [state.ubo])

  useEffect(() => {
    if (!!ubo.address.country && states.length === 0) {
      setUbo({ ...ubo, address: { ...ubo.address, state: '-', city: '-' } })
    }
  }, [ubo.address.country, states])

  useEffect(() => {
    if (!!ubo.address.state && cities.length === 0) {
      setUbo({ ...ubo, address: { ...ubo.address, city: '-' } })
    }
  }, [ubo.address.state, cities])

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>, property: 'govIdImageFile' | 'proofOfAddressFile'): Promise<void> => {
    const fileList = event.target.files

    switch (property) {
    case 'govIdImageFile':
      if (fileList && fileList.length > 0 && fileList[0].type.startsWith('image')) {
        setUbo({ ...ubo, [property]: fileList[0] })
      } else {
        errorToast('Invalid file type. Please upload an image file.')
      }
      break

    case 'proofOfAddressFile':
      if (fileList && fileList.length > 0 && fileList[0].type === 'application/pdf') {
        setUbo({ ...ubo, [property]: fileList[0] })
      } else {
        errorToast('Invalid file type. Please upload an image file.')
      }
    }
  }

  const onSave = (): void => {
    props.onAdd(ubo)
    setIsEditing(false)
  }

  const renderUboForm = (): ReactElement => {
    return (
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: '100px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span style={{ fontSize: 16 }}>Edit ultimate beneficial owner details</span>
            <IconButton onClick={() => {
              setIsEditing(false)
              props.onStopEdit()
            }}>
              <Close style={{ height: 16, width: 'auto', pointerEvents: 'none', stroke: RADOM_COLORS.BLACK }} />
            </IconButton>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <TextInputWithLabel
              label="First name"
              required
              value={ubo.firstName}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, firstName: eventTarget.value })
              }}
            />
            <TextInputWithLabel
              label="Last name"
              required
              value={ubo.lastName}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, lastName: eventTarget.value })
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Birth date</InputLabel>
              <DateSelector onChange={(date) => setUbo({ ...ubo, birthDate: date?.toISOString().split('T')[0] })} value={ubo.birthDate ? parseISO(ubo.birthDate) : undefined} />
            </div>
            <TextInputWithLabel
              label="Email"
              required
              value={ubo.email}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, email: eventTarget.value })
              }}
            />
            <TextInputWithLabel
              label="Phone"
              required
              value={ubo.phone}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, phone: eventTarget.value })
              }}
            />
            <TextInputWithLabel
              label="Tax identification number"
              required
              value={ubo.taxIdentificationNumber}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, taxIdentificationNumber: eventTarget.value })
              }}
            />
            <TextInputWithLabel
              label="Street line"
              required
              value={ubo.address.streetLine}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                setUbo({ ...ubo, address: { ...ubo.address, streetLine: eventTarget.value } })
              }}
            />
            <div style={{ display: 'flex', gap: 10, flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
                <InputLabel>Country</InputLabel>
                <SearchMultiselect
                  isSingleSelect
                  placeholder="Select country"
                  dropdownPlaceholder="No countries found"
                  itemArray={countries}
                  selectedItems={selectedCountry ? [selectedCountry] : []}
                  setSelectedItems={(items) => {
                    const selected = items[0]
                    if (selected) {
                      setUbo({ ...ubo, address: { ...ubo.address, country: selected.key, state: '', city: '' } })
                    }
                  }}
                  keyExtractor={(country) => country.value}
                  labelExtractor={(country) => country.value}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
                <InputLabel>State</InputLabel>
                <SearchMultiselect
                  isSingleSelect
                  disabled={!ubo.address.country || states.length === 0}
                  placeholder={!!ubo.address.country && states.length === 0 ? ubo.address.state : 'Select a state'}
                  dropdownPlaceholder="No states found"
                  itemArray={states}
                  selectedItems={selectedState ? [selectedState] : []}
                  setSelectedItems={(items) => {
                    const selected = items[0]
                    if (selected) {
                      setUbo({ ...ubo, address: { ...ubo.address, state: selected.key, city: '' } })
                    }
                  }}
                  keyExtractor={(state) => state.value}
                  labelExtractor={(state) => state.value}
                />
              </div>
            </div>
            <div style={{ display: 'flex', gap: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
                <InputLabel>City</InputLabel>
                <SearchMultiselect
                  isSingleSelect
                  disabled={!ubo.address.state || cities.length === 0}
                  placeholder={!!ubo.address.state && cities.length === 0 ? ubo.address.city : 'Select a city'}
                  dropdownPlaceholder="No cities found"
                  itemArray={cities}
                  selectedItems={selectedCity ? [selectedCity] : []}
                  setSelectedItems={(items) => {
                    const selected = items[0]
                    if (selected) {
                      setUbo({ ...ubo, address: { ...ubo.address, city: selected.key } })
                    }
                  }}
                  keyExtractor={(city) => city.value}
                  labelExtractor={(city) => city.value}
                />
              </div>
              <div style={{ flex: 1 }}>
                <TextInputWithLabel
                  style={{ flex: 1 }}
                  label="Postal code"
                  required
                  value={ubo.address.postalCode}
                  onChange={(e) => {
                    const eventTarget = e.target as HTMLInputElement
                    setUbo({ ...ubo, address: { ...ubo.address, postalCode: eventTarget.value } })
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Percentage ownership (%)</InputLabel>
              <NumberInput
                label="Percentage Ownership"
                required
                value={ubo.percentageOwnership}
                onUserInput={(value) => setUbo({ ...ubo, percentageOwnership: Number(value) })}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Join date</InputLabel>
              <DateSelector onChange={(date) => setUbo({ ...ubo, relationshipEstablishedAt: date?.toISOString().split('T')[0] })} value={ubo.relationshipEstablishedAt ? parseISO(ubo.relationshipEstablishedAt) : undefined} />
            </div>
            <div>
              <InputLabel>
                <span style={{ marginBottom: 10 }}>Government ID</span>
              </InputLabel>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <FileInput
                  value={ubo.govIdImageFile}
                  onChange={async (evt) => await onFileChange(evt, 'govIdImageFile')}
                />
                { ubo.govIdImageUrl && (
                  <img src={ubo.govIdImageUrl} alt="Government ID" style={{ width: 70, height: 70 }} />
                )}
              </div>
            </div>
            <div>
              <InputLabel>
                <span style={{ marginBottom: 10 }}>Proof of address</span>
              </InputLabel>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <FileInput
                  value={ubo.proofOfAddressFile}
                  onChange={async (evt) => await onFileChange(evt, 'proofOfAddressFile')}
                />
                { ubo.proofOfAddressUrl && (
                  <Viewer
                    fileUrl={ubo.proofOfAddressUrl}
                    plugins={[proofOfAddressDocumentThumbnail, proofOfAddressPageThumbnail]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Worker>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
      <div style={{ marginBottom: 20 }}>
        <PageHeader title="Ownership structure" subTitle="Introduce the key individuals who own or control your business" />
      </div>
      { state.ubos.length > 0 && !isEditing && (
        <ul style={{ padding: 0 }}>
          {state.ubos.map((ubo, index) => (
            <ListItem key={ubo.email}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <span>{ubo.firstName} {ubo.lastName}</span>
                <span style={{ fontSize: '12px', color: RADOM_COLORS.GRAY4 }}>{ubo.email}</span>
                <span style={{ fontSize: '12px', color: RADOM_COLORS.GRAY4 }}>
                  {
                    [
                      ubo.address.streetLine,
                      ubo.address.city,
                      ubo.address.state,
                      ubo.address.postalCode,
                      Country.getCountryByCode(ubo.address.country)?.name
                    ].join(', ')
                  }
                </span>
              </div>
              <IconButton style={{ padding: 0 }} onClick={() => {
                setIsEditing(true)
                props.onEdit(index)
              }}>
                <Edit style={{ height: 16, width: 'auto', pointerEvents: 'none', stroke: RADOM_COLORS.BLACK }} />
              </IconButton>
              <IconButton style={{ padding: 0 }} onClick={() => props.onRemove(index)}>
                <Close style={{ height: 16, width: 'auto', pointerEvents: 'none', stroke: RADOM_COLORS.BLACK }} />
              </IconButton>
            </ListItem>
          ))}
        </ul>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, overflow: 'scroll' }}>
        { isEditing
          ? renderUboForm()
          : (
            <SecondaryButton onClick={() => setIsEditing(true)}>
              Add UBO
            </SecondaryButton>
          ) }
      </div>
      { isEditing
        ? (
          <StickingFooter>
            <PrimaryButton disabled={!canSave} onClick={onSave}>
              Save
            </PrimaryButton>
          </StickingFooter>
        )
        : (

          <PrimaryButton disabled={!isValid || !canSubmit} isLoading={isSubmitting} onClick={props.onSubmit}>
          Continue
          </PrimaryButton>
        )}
    </div>
  )
}

export default Ubos
