import React, { useEffect, useReducer, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Close from '../icons/Close'
import Radom, {
  InputField, InputFieldDataType, IPaymentLink, IProduct,
  IProductUpdate, bonusLevel
} from '../state/Radom'
import Web3 from '../state/User'
import { RADOM_COLORS } from '../util/Constants'
import { Currencies } from '../util/Currencies'
import { SecondsToPaymentPeriod, errorToast, successToast } from '../util/Util'
import { IconButton, PrimaryButton, HoverText, IconLinkButton } from './Button'
import ProductForm, { ProductType } from './ProductForm'
import Dropdown from './Dropdown'
import { InputLabel, InputWithCheckbox, TextInput, UnstyledInput } from './Input'
import Modal from './Modal'
import Spinner from './Spinner'
import Tooltip from './Tooltip'
import Checkbox from './Checkbox'
import { cloneDeep, isEqual, isNil } from 'lodash'
import { ManagedPaymentMethodSelector } from './ManagedMethodMultiselector'
import { ManagedPaymentMethod, getMethod } from '../util/Managed'
import ColorPicker from 'react-best-gradient-color-picker'
import { IProductCreateV2 } from '../views/Products/CreateProduct'
import NumberInput from './NumberInput'
import DateSelector from './DateSelector'

const InputPresets = [
  { label: 'Collect customer’s name', name: 'Name' },
  { label: 'Collect customer’s email', name: 'Email address' }
]

const ChainLogo = styled.img`
  filter: grayscale(1);
  width: auto;
  height: 14px;
  transition: 0.2s ease all;
  opacity: 0.5;
`

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};

    ${ChainLogo} {
      opacity: 1;
      filter: grayscale(0);
    }
  }
`

const InputPresetList = styled.div`
  display: grid;
  gap: 12px;
  align-items: center;
`

const InputPreset = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  width: fit-content;
  transition: 0.2s ease all;

  &:hover {
    color: ${RADOM_COLORS.BLACK};
  }

  &:active {
    opacity: 0.5;
  }
`

const CustomInputList = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) 20px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const emptyInputField = {
  dataType: InputFieldDataType.String,
  inputLabel: '',
  isRequired: true
}

interface IProps {
  edit?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  paymentLink: IPaymentLink
  onFormSubmit: () => void
  onFormChange: (form: Partial<IPaymentLink>) => void
}

const PresaleLinkForm = (props: IProps): React.ReactElement => {
  const [searchParams] = useSearchParams()

  const [closeProductFn, setCloseProductFn] = useState(() => () => {})
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [inputFieldCloseFns, setInputFieldCloseFns] = useState<any>({})
  const [customInputs, setCustomInputs] = useState<InputField[]>([])
  const [products, setProducts] = useState([] as IProduct[])
  const [selectedMethods, setSelectedMethods] = useState<ManagedPaymentMethod[]>(
    props.paymentLink.gateway.managed?.methods.map(m => getMethod(m.network, m.token)) ?? []
  )

  const tokenPresaleData = props.paymentLink.presaleData?.tokenPresaleData
  const timeStrategy = tokenPresaleData?.pricingStrategies?.timeBased

  const [timeInterval, setTimeInterval] = useState<string>(
    timeStrategy?.roundDurationSeconds
      ? (timeStrategy?.roundDurationSeconds / 3600).toString()
      : undefined as any
  )
  const [percentIncrease, setPercentIncrease] = useState<string>(
    timeStrategy?.priceIncreasePercentage
      ? timeStrategy.priceIncreasePercentage * 100
      : undefined as any
  )

  const emptyProduct: any = {
    name: '',
    description: '',
    chargingIntervalSeconds: 0,
    currency: Currencies[0].ticker,
    price: '',
    image: undefined,
    addOns: []
  }

  useEffect(() => {
    if (!props.paymentLink.gateway.managed) {
      return
    }
    setBonusLevels(props.paymentLink.presaleData?.tokenPresaleData?.bonus ?? [])

    props.onFormChange({
      gateway: {
        managed: {
          methods: selectedMethods.map(m => ({
            network: m.hostChain?.name ?? m.name,
            token: m.hostChain?.tokenAddress ?? undefined
          }))
        }
      }
    })
  }, [selectedMethods])

  const [productForm, updateProductForm] = useReducer((p: IProductCreateV2, n: Partial<IProductCreateV2>) => {
    return { ...p, ...n }
  }, emptyProduct)

  const handleUpdateProductForm = (form: IProductUpdate): void => {
    updateProductForm(form)
  }

  const loadProducts = async (): Promise<void> => {
    Radom.getProducts(true)
      .then(products => {
        setProducts(products.filter(p => p.productType?.Presale))
        return products
      })
      .then((products) => {
        if (searchParams.has('product_id')) {
          const selectedProduct = products.find(p => p.id === searchParams.get('product_id'))
          if (selectedProduct) {
            props.onFormChange({ products: [selectedProduct] })
          }
        }
      })
  }

  const onCreateProduct = async (): Promise<void> => {
    try {
      const newProduct = await Radom.createProduct({
        ...productForm,
        chargingIntervalSeconds: productForm.chargingIntervalSeconds !== undefined &&
         productForm.chargingIntervalSeconds <= 0
          ? undefined
          : productForm.chargingIntervalSeconds,
        image: undefined
      } as any)

      if (productForm.image) {
        const formData = new FormData()
        formData.append('entityId', newProduct.id)
        formData.append('imageType', 'productImage')
        formData.append('image', productForm.image)
        await Radom.uploadImage(formData)
      }

      const product = await Radom.getProduct(newProduct.id)

      setProductModalOpen(false)
      successToast('Created product!')

      loadProducts()
        .then(() => {
          props.onFormChange({ products: [...props.paymentLink.products, product] })
        })
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
  }

  const [bonusLevels, setBonusLevels] = useState<bonusLevel[]>([])

  const handleBonusLevelInput = (index: number, field: keyof bonusLevel, value: number): void => {
    const updatedLevels = bonusLevels.map((level, i) => {
      if (i === index) {
        const updatedLevel = { ...level, [field]: value }

        if (field === 'bonus' && updatedLevel.lessThan <= updatedLevel.greaterThan) {
          updatedLevel.lessThan = updatedLevel.greaterThan + 1
        }

        return updatedLevel
      }
      return level
    })
    setBonusLevels(updatedLevels)

    props.onFormChange({
      presaleData: {
        tokenPresaleData: {
          ...tokenPresaleData,
          bonus: updatedLevels
        }
      }
    })
  }

  const onFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    props.onFormSubmit()
  }

  useEffect(() => {
    setCustomInputs(props.paymentLink.inputFields.filter(i => !InputPresets.some(ii => ii.name === i.inputLabel)))
  }, [])

  useEffect(() => {
    const _inputPresets = props.paymentLink.inputFields
      .filter(i => InputPresets.map(i => i.name).includes(i.inputLabel))
    const inputFields = [..._inputPresets, ...customInputs]

    if (isEqual(props.paymentLink.inputFields, inputFields)) {
      return
    }

    props.onFormChange({ inputFields: cloneDeep(inputFields) })
  }, [customInputs])

  useEffect(() => {
    loadProducts()

    Radom.getSellerProfile()
      .then(profile => {
        if (props.paymentLink.gateway.managed) {
          return
        }

        if (profile) {
          if (profile.defaultTokens?.length && !props.edit) {
            props.onFormChange({ gateway: { selfCustodial: { tokens: profile.defaultTokens } } })
          }
        }
      })
  }, [])

  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 2,
      background: 'white',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        padding: '10px 30px',
        top: 0,
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.85)',
        width: '100%',
        zIndex: 1,
        backdropFilter: 'blur(5px)',
        borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`
      }}>
        <IconLinkButton to={'/presales'} type="button" style={{ padding: 5 }}>
          <Close style={{ width: 15, pointerEvents: 'none' }} />
        </IconLinkButton>
        <h1 style={{ fontSize: 18, fontWeight: 400 }}>{props.edit ? 'Edit' : 'Create'} presale</h1>
      </div>
      <div style={{
        fontSize: 14,
        width: 450,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        paddingTop: 90
      }}>
        <form onSubmit={onFormSubmit} style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-between'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 30,
            padding: 40,
            paddingTop: 0
          }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

              <span style={{ fontSize: 16 }}>Payment page</span>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <InputLabel>Token</InputLabel>
                </div>
                <Dropdown
                  preferredAlignment='right'
                  onCloseFn={_closeFn => setCloseProductFn(() => _closeFn)}
                  selectedContent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      flexWrap: 'wrap'
                    }}>
                      {Web3.isLoading && <Spinner />}
                      {(!Web3.isLoading && !props.paymentLink.products.length) && <div>Select token</div>}
                      {
                        !Web3.isLoading &&
                      props.paymentLink.products.map(product => {
                        return <div key={product.id} style={{
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: 5,
                          gap: 10,
                          padding: 5
                        }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                            {product.imageUrl && <img src={product.imageUrl} style={{ width: 30, height: 'auto' }} /> }
                            <div>
                              <span style={{
                                whiteSpace: 'pre',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                maxWidth: '70px'
                              }}>{product.name}</span>
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0,
                                fontSize: 12,
                                opacity: 0.4
                              }}>
                                {Currencies.find(c =>
                                  c.ticker.toLowerCase() === product.currency.toLowerCase())?.icon}
                                <span style={{
                                  whiteSpace: 'pre',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  maxWidth: '70px'
                                }}>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(product.price)}</span>
                              </div>
                            </div>
                          </div>

                        </div>
                      })
                      }
                    </div>
                  }
                  dropdownContent={
                    <div style={{ maxHeight: 300, overflow: 'auto' }}>
                      <DropdownItem
                        style={{ display: 'flex', alignItems: 'center', padding: 15 }}
                        onClick={() => { closeProductFn(); setProductModalOpen(true) }}>
                        <span style={{ color: RADOM_COLORS.BLACK }}>+ Create new token</span>
                      </DropdownItem>
                      {products
                        .filter(p => !props.paymentLink.products.map(prod => prod.id).includes(p.id))
                        .filter(p => !p.isArchived)
                        .map(p =>
                          <DropdownItem
                            key={p.id}
                            style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                            onClick={() => {
                              props.onFormChange({ products: [p] })
                              closeProductFn()
                            }}>
                            {p.imageUrl && <img src={p.imageUrl} style={{ width: 20, height: 'auto' }} /> }
                            <span>{p.name}</span>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                              {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                              <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                            </div>
                            {
                              p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                            <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                              per {SecondsToPaymentPeriod(p.chargingIntervalSeconds).toLowerCase()}
                            </span>
                            }
                          </DropdownItem>
                        )}
                    </div>
                  }
                />
              </div>

              {
                props.paymentLink.gateway.managed &&
                <ManagedPaymentMethodSelector
                  allowTestnets
                  selectedMethods={props.paymentLink.gateway.managed.methods.map(m => getMethod(m.network, m.token))}
                  setSelectedMethods={setSelectedMethods} />
              }

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

              <span style={{ fontSize: 16 }}>Presale options</span>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Fundraise goal</InputLabel>
                    <Tooltip message='If this is enabled, a fixed fundraise goal will need to be set and will be shown on the widget.' />
                  </div>
                </div>

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {

                        ...tokenPresaleData,
                        totalRaiseAmount: isNil(tokenPresaleData?.totalRaiseAmount) ? '' : undefined
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.totalRaiseAmount) } />
                  <span>Set a total fundraise goal to reach</span>
                </InputPreset>
                {
                  !isNil(tokenPresaleData?.totalRaiseAmount) &&
                  <NumberInput
                    disabled={false}
                    value={tokenPresaleData?.totalRaiseAmount}
                    onUserInput={(e) => props.onFormChange({
                      presaleData: {
                        tokenPresaleData: {
                          ...tokenPresaleData,
                          totalRaiseAmount: e
                        }
                      }
                    })}
                    fontSize='14'
                    align='right'
                    required
                  />

                }
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Pricing strategies</InputLabel>
                    <Tooltip message='Set pricing strategies to increase the token presale price.' />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                  <InputPreset
                    onClick={() => {
                      setPercentIncrease('5')
                      setTimeInterval('1')
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            pricingStrategies: {
                              ...tokenPresaleData?.pricingStrategies,
                              timeBased: tokenPresaleData?.pricingStrategies?.timeBased
                                ? undefined
                                : {
                                  startsAt: new Date().toISOString(),
                                  roundDurationSeconds: 3600,
                                  priceIncreasePercentage: 0.05
                                }
                            }
                          }
                        }
                      })
                    }}>
                    <Checkbox
                      size={16}
                      checked={!isNil(tokenPresaleData?.pricingStrategies?.timeBased)} />
                    <span>Increase price by time</span>
                  </InputPreset>
                  {
                    !isNil(tokenPresaleData?.pricingStrategies?.timeBased) &&
                    <div style={{ padding: 20, paddingTop: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <InputLabel style={{ opacity: 0.5 }}>Increase by</InputLabel>
                        <div style={{ display: 'flex', gap: 5 }}>
                          <NumberInput
                            value={percentIncrease}
                            onUserInput={e => {
                              const tokenData = tokenPresaleData
                              setPercentIncrease(e)
                              props.onFormChange({
                                presaleData: {
                                  tokenPresaleData: {
                                    ...tokenData,
                                    pricingStrategies: {
                                      ...tokenData?.pricingStrategies,
                                      timeBased: {
                                        ...tokenData?.pricingStrategies?.timeBased as any,
                                        priceIncreasePercentage: (Number(e) / 100).toString() as any
                                      }
                                    }
                                  }
                                }
                              })
                            }} />
                          <Dropdown
                            selectedContent={<div>%</div>}
                            dropdownContent={<></>} />
                        </div>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <InputLabel style={{ opacity: 0.5 }}>Every</InputLabel>
                        <div style={{ display: 'flex', gap: 5 }}>
                          <NumberInput
                            value={timeInterval}
                            placeholder='2'
                            onUserInput={e => {
                              const tokenData = tokenPresaleData
                              setTimeInterval(e)
                              props.onFormChange({
                                presaleData: {
                                  tokenPresaleData: {
                                    ...tokenData,
                                    pricingStrategies: {
                                      ...tokenData?.pricingStrategies,
                                      timeBased: {
                                        ...tokenData?.pricingStrategies?.timeBased as any,
                                        roundDurationSeconds: Number(e) * 60 * 60
                                      }
                                    }
                                  }
                                }
                              })
                            }} />
                          <Dropdown
                            selectedContent={<div>Hour</div>}
                            dropdownContent={<></>} />
                        </div>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <InputLabel style={{ opacity: 0.5 }}>Starting</InputLabel>
                        <DateSelector
                          value={new Date(tokenPresaleData
                            ?.pricingStrategies?.timeBased?.startsAt)}
                          onChange={e => {
                            const tokenData = tokenPresaleData
                            props.onFormChange({
                              presaleData: {
                                tokenPresaleData: {
                                  ...tokenData,
                                  pricingStrategies: {
                                    ...tokenData?.pricingStrategies,
                                    timeBased: {
                                      ...tokenData?.pricingStrategies?.timeBased as any,
                                      startsAt: e?.toISOString()
                                    }
                                  }
                                }
                              }
                            })
                          }} />
                      </div>
                    </div>
                  }
                </div>

                {
                  false &&
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <InputPreset
                      onClick={() => props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            pricingStrategies: {
                              ...tokenPresaleData?.pricingStrategies,
                              goalBased: tokenPresaleData?.pricingStrategies?.goalBased
                                ? undefined
                                : {
                                  something: 'hehe'
                                }
                            }
                          }
                        }
                      })}>
                      <Checkbox
                        size={16}
                        checked={!isNil(tokenPresaleData?.pricingStrategies?.goalBased)}
                      />
                      <span>Increase price by goal</span>
                    </InputPreset>
                    {
                      !isNil(tokenPresaleData?.pricingStrategies?.goalBased) &&
                      <>
                      Pricing strategy here
                      </>
                    }
                  </div>
                }
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Minimum purchase amount</InputLabel>
                    <Tooltip message='The minimum amount a purchase of tokens can be made for set in the fiat currency, e.g. $5.' />
                  </div>
                </div>

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {

                        ...tokenPresaleData,
                        minimumPurchaseAmount: isNil(tokenPresaleData?.minimumPurchaseAmount) ? '' : undefined
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.minimumPurchaseAmount)} />
                  <span>Set a minimum purchase amount</span>
                </InputPreset>

                {
                  !isNil(tokenPresaleData?.minimumPurchaseAmount) &&
                  <NumberInput
                    disabled={false}
                    value={tokenPresaleData?.minimumPurchaseAmount}
                    onUserInput={(e) => props.onFormChange({
                      presaleData: {
                        tokenPresaleData: {
                          ...tokenPresaleData,
                          minimumPurchaseAmount: e
                        }
                      }
                    })}
                    fontSize='14'
                    align='right'
                    required
                  />

                }
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Bonus Percent</InputLabel>
                  </div>
                </div>

                <InputPreset
                  onClick={() => {
                    if (isNil(tokenPresaleData?.bonus) || tokenPresaleData?.bonus === undefined) {
                      setBonusLevels([{ greaterThan: 0, lessThan: 0, bonus: 0 }])
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            bonus: [{ greaterThan: 0, lessThan: 0, bonus: 0 }]
                          }
                        }
                      })
                    } else {
                      setBonusLevels([])
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            bonus: undefined
                          }
                        }
                      })
                    }
                  }}>
                  <Checkbox
                    size={16}
                    checked={
                      bonusLevels.length !== 0} />
                  <span>Set a bonus percent</span>
                </InputPreset>

                {
                  bonusLevels.map((level, index) => {
                    return (
                      <div key={index} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <InputLabel style={{ opacity: 0.5, fontSize: 12 }}>Purchase</InputLabel>
                        <NumberInput
                          value={level.greaterThan}
                          onUserInput={(e) => handleBonusLevelInput(index, 'greaterThan', parseFloat(e) || 0)}
                          fontSize='11'
                          style={{ padding: '5px 2.5px' }}
                          align='right'
                          required
                        />
                        <InputLabel style={{ opacity: 0.5, fontSize: 12 }}>-</InputLabel>
                        <NumberInput
                          value={level.lessThan}
                          onUserInput={(e) => handleBonusLevelInput(index, 'lessThan', parseFloat(e) || 0)}
                          fontSize='11'
                          style={{ padding: '5px 2.5px' }}
                          align='right'
                          required
                        />
                        <InputLabel style={{ opacity: 0.5, fontSize: 12 }}>tokens,&nbsp;get</InputLabel>
                        <NumberInput
                          value={level.bonus}
                          onUserInput={(e) => handleBonusLevelInput(index, 'bonus', parseFloat(e) || 0)}
                          fontSize='11'
                          style={{ padding: '5px 2.5px' }}
                          align='right'
                          required
                        />

                        <InputLabel style={{ opacity: 0.5, fontSize: 12, gap: 0, flexWrap: 'nowrap' }}>%&nbsp;free</InputLabel>

                        <span
                          style={{
                            fontSize: 20,
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setBonusLevels((prevLevels) => {
                              const updatedLevels = prevLevels.filter((_, i) => i !== index)
                              return updatedLevels
                            })
                            props.onFormChange({
                              presaleData: {
                                tokenPresaleData: {
                                  ...tokenPresaleData,
                                  bonus: bonusLevels.filter((_, i) => i !== index)
                                }
                              }
                            })
                          }}
                        ><Close style={{ width: 12, pointerEvents: 'none' }} /></span>
                      </div>
                    )
                  })
                }

                {
                  bonusLevels.length !== 0 &&
                  <HoverText
                    onClick={() => {
                      setBonusLevels((prevLevels) => {
                        const lastLevel = prevLevels[prevLevels.length - 1]
                        const newGreaterThan = lastLevel ? lastLevel.lessThan : 0
                        return [
                          ...prevLevels,
                          { greaterThan: newGreaterThan, lessThan: newGreaterThan + 1, bonus: 0 }
                        ]
                      })
                    }}
                  >+ Add bonus range</HoverText>
                }

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {
                        ...tokenPresaleData,
                        customizations: {
                          ...tokenPresaleData?.customizations,
                          bonusTitle: isNil(tokenPresaleData?.customizations?.bonusTitle) ? '' : undefined
                        }
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.customizations?.bonusTitle)} />
                  <span>Set a custom bonusTitle</span>
                </InputPreset>

                {
                  !isNil(tokenPresaleData?.customizations?.bonusTitle) &&
                  <TextInput
                    value={tokenPresaleData?.customizations?.bonusTitle}
                    onChange={d => {
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            customizations: {
                              ...tokenPresaleData?.customizations,
                              bonusTitle: d.target.value.slice(0, 36)
                            }
                          }
                        }
                      })
                    }} />
                }
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Presale duration</InputLabel>
                    <Tooltip message='Set optional start and end dates for the presale.' />
                  </div>
                </div>

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {

                        ...tokenPresaleData,
                        startsAt: isNil(tokenPresaleData?.startsAt) ? '' : undefined
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.startsAt)} />
                  <span>Set a start date</span>
                </InputPreset>

                {
                  !isNil(tokenPresaleData?.startsAt) &&
                  <DateSelector
                    value={
                      tokenPresaleData?.startsAt
                        ? new Date(tokenPresaleData?.startsAt)
                        : undefined
                    }
                    onChange={d => {
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {

                            ...tokenPresaleData,
                            startsAt: d?.toISOString()
                          }
                        }
                      })
                    }} />
                }

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {

                        ...tokenPresaleData,
                        endsAt: isNil(tokenPresaleData?.endsAt) ? '' : undefined
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.endsAt)} />
                  <span>Set an end date</span>
                </InputPreset>

                {
                  !isNil(tokenPresaleData?.endsAt) &&
                  <DateSelector
                    value={
                      tokenPresaleData?.endsAt
                        ? new Date(tokenPresaleData?.endsAt)
                        : undefined
                    }
                    onChange={d => {
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            endsAt: d?.toISOString()
                          }
                        }
                      })
                    }} />
                }

              </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              <span style={{ fontSize: 16 }}>Customizations</span>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Presale customizations</InputLabel>
                  </div>
                </div>

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {
                        ...tokenPresaleData,
                        customizations: {
                          ...tokenPresaleData?.customizations,
                          title: isNil(tokenPresaleData?.customizations?.title) ? '' : undefined
                        }
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.customizations?.title)} />
                  <span>Set a custom title</span>
                </InputPreset>

                {
                  !isNil(tokenPresaleData?.customizations?.title) &&
                  <TextInput
                    value={tokenPresaleData?.customizations?.title}
                    onChange={d => {
                      props.onFormChange({
                        presaleData: {
                          tokenPresaleData: {
                            ...tokenPresaleData,
                            customizations: {
                              ...tokenPresaleData?.customizations,
                              title: d.target.value.slice(0, 36)
                            }
                          }
                        }
                      })
                    }} />
                }

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {
                        ...tokenPresaleData,
                        customizations: {
                          ...tokenPresaleData?.customizations,
                          titleFontSize: isNil(tokenPresaleData?.customizations?.titleFontSize) ? '' : undefined
                        }
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!isNil(tokenPresaleData?.customizations?.titleFontSize)} />
                  <span>Change title font size</span>
                </InputPreset>

                {
                  !isNil(tokenPresaleData?.customizations?.titleFontSize) &&
                  <Dropdown
                    key={tokenPresaleData?.customizations?.titleFontSize}
                    selectedContent={tokenPresaleData?.customizations?.titleFontSize}
                    dropdownContent={
                      Array.from(Array(51)).map((_, i) => i + 20).map(i =>
                        <DropdownItem
                          onClick={() => {
                            props.onFormChange({
                              presaleData: {
                                tokenPresaleData: {
                                  ...tokenPresaleData,
                                  customizations: {
                                    ...tokenPresaleData?.customizations,
                                    titleFontSize: i.toString()
                                  }
                                }
                              }
                            })
                          }}
                          key={i}>
                          {i}
                        </DropdownItem>
                      )}
                  />
                }

                <InputPreset
                  onClick={() => props.onFormChange({
                    presaleData: {
                      tokenPresaleData: {
                        ...tokenPresaleData,
                        customizations: {
                          ...tokenPresaleData?.customizations,
                          hideTotalRaiseGoal: !tokenPresaleData?.customizations?.hideTotalRaiseGoal
                        }
                      }
                    }
                  })}>
                  <Checkbox
                    size={16}
                    checked={!!tokenPresaleData?.customizations?.hideTotalRaiseGoal} />
                  <span>Hide total raise amount</span>
                </InputPreset>
              </div>

              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ display: 'flex', gap: 3 }}>
                    <InputLabel style={{ opacity: 0.5 }}>Network fee</InputLabel>
                    <Tooltip message='If this is enabled, the network fee will be added to the checkout total and paid by the customer.' />
                  </div>
                </div>

                <InputPreset
                  onClick={() => props.onFormChange({
                    chargeCustomerNetworkFee: !props.paymentLink.chargeCustomerNetworkFee
                  })}>
                  <Checkbox
                    size={16}
                    checked={!!props.paymentLink.chargeCustomerNetworkFee} />
                  <span>Pass network fee charge to customer</span>
                </InputPreset>
              </div>

              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                  <InputLabel style={{ opacity: 1, gap: 2 }}>
                    <span style={{ opacity: 0.5 }}>Email receipt</span>
                  </InputLabel>
                </div>
                <InputPresetList>
                  <InputPreset
                    onClick={() => {
                      if (!props.paymentLink.sendEmailReceipt && !props.paymentLink.inputFields.map(i => i.inputLabel).includes('Email address')) {
                        props.onFormChange({
                          inputFields: [...props.paymentLink.inputFields, { dataType: InputFieldDataType.Email, inputLabel: 'Email address', isRequired: true }]
                        })
                      }
                      props.onFormChange({ sendEmailReceipt: !props.paymentLink.sendEmailReceipt })
                    } }>
                    <Checkbox
                      size={16}
                      checked={!!props.paymentLink.sendEmailReceipt} />
                    <span>Send customer an email receipt</span>
                  </InputPreset>
                </InputPresetList>
              </div>

              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                  <InputLabel style={{ opacity: 1, gap: 2 }}>
                    <span style={{ opacity: 0.5 }}>Input fields</span>
                    <Tooltip message='Create inputs to collect customer data.' />
                  </InputLabel>
                </div>
                <InputPresetList>
                  {
                    InputPresets.map((input, index) =>
                      <InputPreset
                        key={index}
                        onClick={() => {
                          const newInputs = props.paymentLink.inputFields.slice()
                          if (props.paymentLink.inputFields.map(i => i.inputLabel).includes(input.name)) {
                          // Remove preset input from inputFields
                            props.onFormChange({
                              inputFields: newInputs.filter(i => i.inputLabel !== input.name)
                            })
                          } else {
                          // Add preset input to inputFields
                            const presetInput: InputField = {
                              ...emptyInputField,
                              inputLabel: input.name,
                              dataType: input.name === 'Email address' ? InputFieldDataType.Email : InputFieldDataType.String
                            }
                            props.onFormChange({
                              inputFields: [...newInputs, presetInput]
                            })
                          }
                        }}>
                        <Checkbox
                          size={16}
                          checked={props.paymentLink.inputFields.map(i => i.inputLabel).includes(input.name)} />
                        <span>{input.label}</span>
                      </InputPreset>
                    )
                  }
                  <InputPreset
                    onClick={() => {
                      const checked = customInputs.length > 0
                      if (checked) {
                        setCustomInputs([])
                      } else {
                        setCustomInputs([{ ...emptyInputField }])
                      }
                    }}>
                    <Checkbox size={16} checked={customInputs.length > 0} />
                    <span>Add custom fields</span>
                  </InputPreset>
                </InputPresetList>
                {
                  customInputs.length > 0 &&
                  <CustomInputList>
                    {
                      customInputs
                        .map((field, fieldIndex) => [
                          <Dropdown
                            key={`${fieldIndex}-dataType`}
                            onCloseFn={fn => {
                              const newInputFieldFns = cloneDeep(inputFieldCloseFns)
                              newInputFieldFns[fieldIndex] = fn
                              setInputFieldCloseFns(newInputFieldFns)
                            }}
                            selectedContent={
                              <div style={{ fontSize: 14, minWidth: 'max-content' }}>
                                { InputFieldDataType[field.dataType] }
                              </div>
                            }
                            dropdownContent={
                              Object.entries(InputFieldDataType)
                                .filter(c => field.dataType !== c[0])
                                .map((c, i) =>
                                  <DropdownItem
                                    key={i}
                                    style={{ fontSize: 14 }}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      const newInputs = customInputs.slice()
                                      newInputs[fieldIndex].dataType = c[0] as InputFieldDataType
                                      inputFieldCloseFns[fieldIndex]?.()
                                      setCustomInputs(newInputs)
                                    }}
                                  >
                                    {c[1]}
                                  </DropdownItem>
                                )
                            } />,
                          <InputWithCheckbox key={`${fieldIndex}-input`}>
                            <UnstyledInput
                              type="text"
                              value={field.inputLabel}
                              placeholder="Input label"
                              onChange={(e) => {
                                const val = (e.target as HTMLInputElement).value
                                const newInputs = cloneDeep(customInputs.slice())
                                newInputs[fieldIndex].inputLabel = val
                                setCustomInputs(newInputs)
                              }} />
                            <div style={{
                              display: 'flex',
                              gap: 4,
                              color: RADOM_COLORS.GRAY_DARKER,
                              alignItems: 'center',
                              borderLeft: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                              paddingLeft: '8px',
                              cursor: 'pointer',
                              userSelect: 'none'
                            }}
                            onClick={() => {
                              const newInputs = customInputs.slice()
                              newInputs[fieldIndex].isRequired = !newInputs[fieldIndex].isRequired
                              setCustomInputs(newInputs)
                            }}>
                              <span style={{ fontSize: 12 }}>Optional</span>
                              <Checkbox size={15} checked={!field.isRequired} />
                            </div>
                          </InputWithCheckbox>,
                          <div style={{ justifySelf: 'end' }} key={`${fieldIndex}-close`}>
                            <IconButton type="button" style={{ padding: 5 }} onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              const newInputs = customInputs.slice()
                              newInputs.splice(fieldIndex, 1)
                              setCustomInputs(newInputs)
                            }}>
                              <Close style={{ width: 12, pointerEvents: 'none' }} />
                            </IconButton>
                          </div>
                        ])
                    }
                  </CustomInputList>
                }
                {
                  customInputs.length > 0 &&
                  customInputs.length < 2 &&
                  <HoverText
                    style={{ padding: 10, width: 'fit-content' }}
                    onClick={() => {
                      const newInputs = customInputs.slice()
                      newInputs.push({ ...emptyInputField })
                      setCustomInputs(newInputs)
                    }}>
                      + Add input field
                  </HoverText>
                }
              </div>

              <div style={{ display: 'flex', gap: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <InputLabel>Panel color</InputLabel>
                  <div style={{ width: 'fit-content' }}>
                    <Dropdown
                      overflow='initial'
                      innerStyle={{ maxHeight: '100vh' }}
                      selectedContent={
                        <div
                          style={{
                            width: 25,
                            height: 25,
                            borderRadius: 5,
                            background: props.paymentLink.customizations.leftPanelColor
                          }}
                        />
                      }
                      dropdownContent={
                        <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                          <ColorPicker
                            value={props.paymentLink.customizations.leftPanelColor}
                            onChange={c => {
                              props.onFormChange({
                                customizations: { ...props.paymentLink.customizations, leftPanelColor: c }
                              })
                            }}
                          />
                          <TextInput
                            value={props.paymentLink.customizations.leftPanelColor}
                            onChange={v => props.onFormChange({
                              customizations: { ...props.paymentLink.customizations, leftPanelColor: v.target.value }
                            })}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <InputLabel>Button color</InputLabel>
                  <div style={{ width: 'fit-content' }}>
                    <Dropdown
                      overflow='initial'
                      innerStyle={{ maxHeight: '100vh' }}
                      selectedContent={
                        <div style={{
                          width: 25,
                          height: 25,
                          borderRadius: 5,
                          background: props.paymentLink.customizations.primaryButtonColor
                        }} />
                      }
                      dropdownContent={
                        <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                          <ColorPicker
                            value={props.paymentLink.customizations.primaryButtonColor}
                            onChange={c => {
                              props.onFormChange({
                                customizations: { ...props.paymentLink.customizations, primaryButtonColor: c }
                              })
                            }}
                          />
                          <TextInput
                            value={props.paymentLink.customizations.primaryButtonColor}
                            onChange={v => props.onFormChange({
                              customizations: {
                                ...props.paymentLink.customizations,
                                primaryButtonColor: v.target.value
                              }
                            })}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style={{
            padding: '20px 40px',
            position: 'sticky',
            bottom: 0,
            background: 'linear-gradient(to top, white, white, transparent)',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10
          }}>
            <PrimaryButton style={{ marginTop: '32px', width: '100%', gap: 8 }} isLoading={props.isSubmitting}>
              {props.edit
                ? 'Save changes'
                : <>
                  <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
                  <span>Create presale</span>
                </>
              }
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div >
    <Modal
      visible={productModalOpen}
      onClose={() => setProductModalOpen(false)}
      title='Create token'>
      <ProductForm
        modal
        form={productForm}
        allowedProductTypes={[ProductType.Presale]}
        onFormSubmit={onCreateProduct}
        onFormUpdate={handleUpdateProductForm}
        isSubmitting={props.isSubmitting ?? false}
        isLoading={props.isLoading ?? false}
      />
    </Modal>
  </>
}

export default PresaleLinkForm
