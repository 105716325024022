import { Viewer, Worker } from '@react-pdf-viewer/core'
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail'
import { City, Country, State } from 'country-state-city'
import React, { ReactElement, useEffect, useMemo } from 'react'

import { PrimaryButton } from '../../components/Button'
import FileInput from '../../components/FileInput'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import SearchMultiselect from '../../components/SearchMultiselect'
import Spinner from '../../components/Spinner'
import { BusinessAddress as BusinessAddressState } from '../../state/Radom'
import { errorToast, pageThumbnailPlugin } from '../../util/Util'

interface IProps {
  state: BusinessAddressState & { proofOfAddressFile?: File, proofOfAddressUrl?: string }
  isSubmitting: boolean
  onUpdate: (value: any) => void
  onSubmit: () => void
}

const BusinessAddress = ({ state, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const proofOfAddressDocumentThumbnail = thumbnailPlugin({ renderSpinner: () => <Spinner />, thumbnailWidth: 100 })
  const { Cover } = proofOfAddressDocumentThumbnail

  const proofOfAddressPageThumbnail = pageThumbnailPlugin({
    PageThumbnail: (
      <div style={{ cursor: 'pointer', width: '100px' }} onClick={() => window.open(state.proofOfAddress)}>
        <Cover getPageIndex={() => 0} width={100} />
      </div>
    )
  })

  const isValid = useMemo(() => ({
    streetLine: state.streetLine !== '',
    city: state.city !== '',
    state: state.state !== '',
    postalCode: state.postalCode !== '',
    country: state.country !== '',
    proofOfAddressFile: !!state.proofOfAddressFile || !!state.proofOfAddress,
    employerIdentificationNumber: (!!state.country && state.country.toLocaleLowerCase() !== 'us') || !!state.employerIdentificationNumber
  }), [state])

  const isFormValid = useMemo(() => Object.values(isValid).every((value) => value), [isValid])

  const countries = useMemo(() => Country
    .getAllCountries()
    .map((country) => ({
      key: country.isoCode,
      value: country.name
    })),
  [])

  const states = useMemo(() => State
    .getStatesOfCountry(state.country)
    .map((state) => ({
      key: state.isoCode,
      value: state.name
    })),
  [state.country])

  const cities = useMemo(() => City
    .getCitiesOfState(state.country, state.state)
    .map((city) => ({
      key: city.name,
      value: city.name
    })),
  [state.country, state.state])

  const selectedCountry = useMemo(
    () => countries.find((country) => country.key === state.country),
    [state.country, countries]
  )

  const selectedState = useMemo(
    () => states.find((s) => s.key === state.state),
    [state.state, states]
  )

  const selectedCity = useMemo(
    () => cities.find((city) => city.key === state.city),
    [state.city, cities]
  )

  useEffect(() => {
    if (!!state.country && states.length === 0) {
      onUpdate({ state: '-', city: '-' })
    }
  }, [state.country, states])

  useEffect(() => {
    if (!!state.state && cities.length === 0) {
      onUpdate({ city: '-' })
    }
  }, [state.state, cities])

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const fileList = event.target.files

    if (fileList && fileList.length > 0 && fileList[0].type === 'application/pdf') {
      onUpdate({ proofOfAddressFile: fileList[0] })
    } else {
      errorToast('Invalid file type. Please upload an image file.')
    }
  }

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
        <div style={{ marginBottom: 20 }}>
          <PageHeader title="Tell us where you're located" subTitle="Share your business's physical address" />
        </div>
        <TextInputWithLabel
          label="Street line"
          required
          value={state.streetLine}
          onChange={(e) => {
            const eventTarget = e.target as HTMLInputElement
            onUpdate({ streetLine: eventTarget.value })
          }}
        />
        <div style={{ display: 'flex', gap: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
            <InputLabel>Country</InputLabel>
            <SearchMultiselect
              isSingleSelect
              placeholder="Select country"
              dropdownPlaceholder="No countries found"
              itemArray={countries}
              selectedItems={selectedCountry ? [selectedCountry] : []}
              setSelectedItems={(items) => {
                const selected = items[0]
                if (selected) {
                  onUpdate({ country: selected.key, state: '', city: '' })
                }
              }}
              keyExtractor={(country) => country.value}
              labelExtractor={(country) => country.value}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
              <InputLabel>State</InputLabel>
              <SearchMultiselect
                isSingleSelect
                disabled={!state.country || states.length === 0}
                placeholder={!!state.country && states.length === 0 ? '-' : 'Select a state'}
                dropdownPlaceholder="No states found"
                itemArray={states}
                selectedItems={selectedState ? [selectedState] : []}
                setSelectedItems={(items) => {
                  const selected = items[0]
                  if (selected) {
                    onUpdate({ state: selected.key, city: '' })
                  }
                }}
                keyExtractor={(state) => state.value}
                labelExtractor={(state) => state.value}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
            <InputLabel>City</InputLabel>
            <SearchMultiselect
              isSingleSelect
              disabled={!state.state || cities.length === 0}
              placeholder={!!state.state && cities.length === 0 ? '-' : 'Select a city'}
              dropdownPlaceholder="No cities found"
              itemArray={cities}
              selectedItems={selectedCity ? [selectedCity] : []}
              setSelectedItems={(items) => {
                const selected = items[0]
                if (selected) {
                  onUpdate({ city: selected.key })
                }
              }}
              keyExtractor={(city) => city.value}
              labelExtractor={(city) => city.value}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TextInputWithLabel
              label="Postal code"
              required
              value={state.postalCode}
              onChange={(e) => {
                const eventTarget = e.target as HTMLInputElement
                onUpdate({ postalCode: eventTarget.value })
              }}
            />
          </div>
        </div>
        <div>
          <InputLabel>
            <span style={{ marginBottom: 10 }}>Proof of address</span>
          </InputLabel>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <FileInput
              value={state.proofOfAddressFile}
              onChange={async (evt) => await onFileChange(evt)}
            />
            { state.proofOfAddressUrl && (
              <Viewer
                fileUrl={state.proofOfAddressUrl}
                plugins={[proofOfAddressDocumentThumbnail, proofOfAddressPageThumbnail]}
              />
            )}
          </div>
        </div>
        { !!state.country && state.country.toLocaleLowerCase() === 'us' && (
          <TextInputWithLabel
            label="Business EIN"
            required
            value={state.employerIdentificationNumber || ''}
            onChange={(e) => {
              const eventTarget = e.target as HTMLInputElement
              onUpdate({ employerIdentificationNumber: eventTarget.value })
            }}
          />
        )}
        <PrimaryButton
          disabled={!isFormValid}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          Continue
        </PrimaryButton>
      </div>
    </Worker>
  )
}

export default BusinessAddress
