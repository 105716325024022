import { observer } from 'mobx-react-lite'
import React, { useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import { ProfilePicture } from '../../components/ProfilePicture'
import Radom, { IOrganization } from '../../state/Radom'
import Web3 from '../../state/User'
import { RADOM_COLORS } from '../../util/Constants'
import Spinner from '../../components/Spinner'
import { isEqual, pickBy } from 'lodash'
import AppearTransition from '../../components/AppearTransition'
import { errorToast, successToast } from '../../util/Util'
import Switch from '../../components/Switch'
import Edit from '../../icons/Edit'

export const RADOM_TERMINAL_ENABLED_KEY = 'RADOM_TERMINAL_ENABLED'

const NameInput = styled.input<{ $isEditing: boolean }>`
    background: transparent;
    width: 100%;
    border: none;
    box-shadow: none;
    font-size: 24px;
    border-radius: 0;
    padding-bottom: 4px;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid ${({ $isEditing }) => $isEditing ? RADOM_COLORS.GRAY_DARK : 'transparent'};

    :focus {
      border-color: ${RADOM_COLORS.ORANGE};
    }
  `

const SettingsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin: auto;
  flex-flow: column nowrap;
  font-size: 14px;

  > *:not(:nth-last-child(1)) {
    border-bottom: 2px solid ${RADOM_COLORS.GRAY_LIGHTEST}
  }

  > * {
    padding: 20px;
  }
`

const SettingsHeader = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
`

const ProfileFooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
  background-color: white;
  border-top: 1px solid ${RADOM_COLORS.GRAY_MED};
`

const Profile = observer(() => {
  const emptySellerProfile: any = {
    organizationId: '',
    name: '',
    logoUrl: '',
    website: '',
    telegram: '',
    discord: '',
    twitter: '',
    imageBytes: undefined,
    defaultTokens: [],
    defaultChains: []
  }

  const [profile, updateProfile] = useReducer((prev, next) => {
    const parsedNext = Object.fromEntries(Object.entries(next).filter(([_, v]) => v != null))
    return { ...prev, ...parsedNext }
  }, emptySellerProfile)
  const [isLoading, setIsLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [profileSnapshot, setProfileSnapshot] = useState<IOrganization>(profile)
  const [profileChanges, setProfileChanges] = useState<Partial<IOrganization>>({})
  const [imgUrl, setImgUrl] = useState<string | undefined>()
  const [imgFile, setImgFile] = useState<File | undefined>()

  const [isTerminalModeEnabled, setIsTerminalModeEnabled] = useState(false)

  useEffect(() => {
    setIsTerminalModeEnabled(!!localStorage.getItem(RADOM_TERMINAL_ENABLED_KEY))
  }, [])

  const onUpdateProfile = async (): Promise<void> => {
    if (isUpdating) return
    setIsUpdating(true)
    try {
      await Radom.updateSellerProfile({ organizationId: profile.organizationId, ...profileChanges })
      Radom.getSellerProfile()
      setProfileSnapshot(profile)
      setIsEditing(false)
      successToast('Successfully updated seller profile')
    } catch (err) {
      console.error('Failed to create seller profile', err)
      errorToast(err.response?.data?.error || err.message)
    }
    setIsUpdating(false)
  }

  const onEdit = (): void => {
    setIsEditing(true)
  }

  const onCancelEdit = (): void => {
    setIsEditing(false)
    updateProfile(profileSnapshot)
  }

  useEffect(() => {
    Web3.connectedAccount
      .then(() => {
        Radom.getSellerProfile()
          .then(p => {
            updateProfile(p)
            setProfileSnapshot(p)
            setIsEditing(false)
            if (p.logoUrl) {
              setImgUrl(p.logoUrl)
            }
          })
          .catch(_ => {
            console.error('Failed')
            const emptyProfile = {
              sellerAddress: Web3.accounts[0]
            }
            updateProfile(emptyProfile)
            setProfileSnapshot({ ...profile, ...emptyProfile })
          })
          .finally(() => setIsLoading(false))
      })
  }, [])

  useEffect(() => {
    const changes = pickBy(profile, (v, k) => {
      return k === 'sellerAddress' ||
        k === 'defaultChains' || k === 'defaultTokens' ||
        (!isEqual(profileSnapshot[k], v))
    })
    setProfileChanges({ ...changes })
  }, [profile])

  useEffect(() => {
    if (imgFile) {
      setImgUrl(URL.createObjectURL(imgFile))
      imgFile.arrayBuffer()
        .then(b => {
          updateProfile({
            imageBytes: Array.from(new Uint8Array(b))
          })
        })
    }
  }, [imgFile])

  if (isLoading) {
    return <Spinner />
  }

  return <SettingsContainer>
    <SettingsHeader>
      <ProfilePicture
        imgUrl={imgUrl}
        imgFile={imgFile}
        setImgFile={setImgFile}
        isEditing={isEditing}
      />
      <NameInput
        value={profile.name}
        disabled={!isEditing}
        placeholder={profile.sellerAddress}
        onChange={(e) => updateProfile({ name: (e.target as HTMLInputElement).value })}
        $isEditing={isEditing}
      />

      <AppearTransition
        in={!isEditing}
        duration={200}
        transitionId="profile-edit">
        <SecondaryButton onClick={onEdit} style={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          padding: '5px 8px',
          border: 0,
          color: RADOM_COLORS.GRAY_DARKER
        }}>
          <span>Edit</span>
          <Edit stroke={RADOM_COLORS.GRAY_DARKER} style={{ height: 16, width: 'auto' }} />
        </SecondaryButton>
      </AppearTransition>
    </SettingsHeader>

    { /* Social Media Links */}
    <div>
      <InputLabel style={{ marginBottom: '20px' }}>Social media links</InputLabel>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '20px'
      }}>
        <TextInputWithLabel
          label="Website URL"
          value={profile.website}
          disabled={!isEditing}
          placeholder="https://radom.com/"
          onChange={(e) => updateProfile({ website: (e.target as HTMLInputElement).value })}
        />
        <TextInputWithLabel
          label="Telegram"
          value={profile.telegram}
          disabled={!isEditing}
          placeholder="@radomnetwork"
          onChange={(e) => updateProfile({ telegram: (e.target as HTMLInputElement).value })}
        />
        <TextInputWithLabel
          label="Discord"
          value={profile.discord}
          disabled={!isEditing}
          placeholder="https://discord.gg/invite/aX3Rtv"
          onChange={(e) => updateProfile({ discord: (e.target as HTMLInputElement).value })}
        />
        <TextInputWithLabel
          label="Twitter"
          value={profile.twitter}
          disabled={!isEditing}
          placeholder="@radomnetwork"
          onChange={(e) => updateProfile({ twitter: (e.target as HTMLInputElement).value })}
        />
      </div>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <InputLabel>Terminal mode</InputLabel>
      <Switch
        disabled={!isEditing}
        checked={isTerminalModeEnabled}
        onClick={() => {
          if (localStorage.getItem(RADOM_TERMINAL_ENABLED_KEY)) {
            localStorage.removeItem(RADOM_TERMINAL_ENABLED_KEY)
            setIsTerminalModeEnabled(false)
          } else {
            localStorage.setItem(RADOM_TERMINAL_ENABLED_KEY, 'ENABLED')
            setIsTerminalModeEnabled(true)
          }
        }} />
    </div>

    {
      <AppearTransition
        in={isEditing}
        duration={200}
        transitionId="update-cancel">
        <ProfileFooter>
          {
            <SecondaryButton
              style={{ color: RADOM_COLORS.GRAY_DARKER, border: 0 }}
              onClick={onCancelEdit}>
              Cancel
            </SecondaryButton>
          }
          <PrimaryButton
            isLoading={isUpdating}
            style={{ width: '360px' }}
            disabled={Object.keys(profileChanges).length === 0}
            onClick={onUpdateProfile}>
            Save changes
          </PrimaryButton>
        </ProfileFooter>
      </AppearTransition>
    }
  </SettingsContainer>
})

export default Profile
