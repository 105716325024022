import { observer } from 'mobx-react'
import React, { ReactElement, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '../../components/Animations'
import BreadCrumbs from '../../components/Breadcrumbs'
import Radom, { Onramp } from '../../state/Radom'
import Spinner from '../../components/Spinner'
import LinkExternal from '../../components/LinkExternal'
import { Section, SubTitle, SectionContent, SectionItem } from '../../components/PaymentDetails'
import TimeText from '../../components/TimeText'
import { RADOM_COLORS } from '../../util/Constants'
import { StatusBadge } from '../CheckoutSessions'
import { ExternalLinkText } from '../Events'
import { camelCase } from 'lodash'
import { formatCurrency, shortAddress } from '../../util/Util'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import { formatExplorerUrl } from '../../util/Chains'
import { getMethod } from '../../util/Managed'

const OnrampView = observer((): ReactElement => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [onramp, setOnramp] = useState<Onramp>()

  useEffect(() => {
    if (!params.id) {
      return
    }

    setIsLoading(true)
    Radom.getOnramp(params.id)
      .then(s => setOnramp(s))
      .catch(e => console.error('Failed to look up onramp', e))
      .finally(() => setIsLoading(false))
  }, [])

  console.log('loading')

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 140, gap: 20 }}>
      <Spinner />
    </div>
  }

  if (!onramp) {
    console.log('not loading')
    return <></>
  }

  return <Container style={{ flexDirection: 'column', fontSize: 14, gap: 30 }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <BreadCrumbs items={[
        { item: 'Onramps', to: '/onramps' },
        { item: onramp.id, to: '.' }
      ]} />

    </div>

    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Onramp status
      </SubTitle>

      <SectionContent>
        <SectionItem>
          <SubTitle>ID</SubTitle>
          {onramp.id}
        </SectionItem>

        <SectionItem>
          <SubTitle>Status</SubTitle>
          <StatusBadge status={camelCase(onramp.status)}>{onramp.status}</StatusBadge>
        </SectionItem>

        <SectionItem>
          <SubTitle>Created at</SubTitle>
          <div>
            <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(onramp.createdAt)} />
          </div>
        </SectionItem>

        {
          onramp.completedAt &&
          <SectionItem>
            <SubTitle>Completed at</SubTitle>
            <div>
              <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(onramp.completedAt)} />
            </div>
          </SectionItem>
        }

        <SectionItem>
          <SubTitle>Onramp asset</SubTitle>
          <PaymentMethodDisplay method={{ network: onramp.toNetwork, token: onramp.toToken }} disableTooltip={false} />
        </SectionItem>

        <SectionItem>
          <SubTitle>Outgoing address</SubTitle>
          <ExternalLinkText
            onClick={e => e.stopPropagation()}
            target="_blank" rel="noreferrer"
            href={formatExplorerUrl(
              onramp.toNetwork,
              'address',
              onramp.toAddress
            )}>
            {shortAddress(onramp.toAddress)}
            <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
          </ExternalLinkText>
        </SectionItem>
      </SectionContent>
    </Section>

    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Onramp payment details
      </SubTitle>

      <SectionContent numColumns={2}>
        <SectionItem>
          <SubTitle>Incoming payment</SubTitle>
          {formatCurrency(onramp.amount, onramp.fromCurrency)} via {onramp.paymentRail}
        </SectionItem>

        <SectionItem>
          <SubTitle>Payment details</SubTitle>
          <div>
            <div style={{ display: 'flex', gap: 5 }}>
              <span style={{ opacity: 0.5 }}>Account name</span>
              <span>{onramp.paymentDetails.accountHolderName}</span>
            </div>

            <div style={{ display: 'flex', gap: 5 }}>
              <span style={{ opacity: 0.5 }}>IBAN</span>
              <span>{onramp.paymentDetails.iban}</span>
            </div>

            <div style={{ display: 'flex', gap: 5 }}>
              <span style={{ opacity: 0.5 }}>BIC</span>
              <span>{onramp.paymentDetails.bic}</span>
            </div>

            <div style={{ display: 'flex', gap: 5 }}>
              <span style={{ opacity: 0.5 }}>Deposit message</span>
              <span>{onramp.paymentDetails.depositMessage}</span>
            </div>

            <div style={{ display: 'flex', gap: 5 }}>
              <span style={{ opacity: 0.5 }}>Bank name</span>
              <span>{onramp.paymentDetails.bankName}</span>
            </div>
          </div>
        </SectionItem>

      </SectionContent>
    </Section>

    {
      onramp.receipt &&
      <Section>
        <SubTitle style={{ fontSize: 18 }}>
          Onramp receipt
        </SubTitle>

        <SectionContent numColumns={2}>

          <SectionItem>
            <SubTitle>Outgoing amount</SubTitle>
            <div>
              {formatCurrency(onramp.receipt.finalAmount, getMethod(onramp.toNetwork, onramp.toToken).ticker)}
            </div>
          </SectionItem>

          <SectionItem>
            <SubTitle>Transaction hash</SubTitle>
            <ExternalLinkText
              onClick={e => e.stopPropagation()}
              target="_blank" rel="noreferrer"
              href={formatExplorerUrl(
                onramp.toNetwork,
                'tx',
                onramp.receipt.txHash
              )}>
              {shortAddress(onramp.receipt.txHash)}
              <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
            </ExternalLinkText>
          </SectionItem>

        </SectionContent>
      </Section>

    }

  </Container>
})

export default OnrampView
