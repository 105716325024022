import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { Container } from '../../components/Animations'
import { PrimaryButton } from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import Radom, { Onramp } from '../../state/Radom'
import TimeText from '../../components/TimeText'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import { formatCurrency } from '../../util/Util'
import { StatusBadge } from '../CheckoutSessions'
import { camelCase } from 'lodash'
import Pagination from '../../components/Pagination'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Checkbox from '../../components/Checkbox'
import { RADOM_COLORS } from '../../util/Constants'

enum OnrampStatusFilter {
  AwaitingFunds = 'AwaitingFunds',
  FundsReceived = 'FundsReceived',
  Complete = 'Complete'
}

const Onramps = observer((): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [onramps, setOnramps] = useState<Onramp[]>([])

  const [filters, setFilters] = useState<OnrampStatusFilter[]>([])

  const pageSize = 20
  const [curPage, setCurPage] = useState(0)
  const [totalSessions, setTotalSessions] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    setOnramps([])
    Radom.listOnramps(curPage * pageSize, pageSize, filters)
      .then(r => {
        setTotalSessions(r.totalCount)
        setOnramps(r.data)
      })
      .finally(() => setIsLoading(false))
  }, [curPage, filters.length])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Onramps' subTitle='Manage your onramp transfers' />
        {
          false &&
          <PrimaryButton style={{ gap: 8 }} onClick={() => {}}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Create onramp</span>
          </PrimaryButton>
        }
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content', gap: 10 }}>
        {
          filters.length > 0 &&
          <div style={{ display: 'flex', gap: 10 }}>
            {
              filters.map(f => <div
                style={{ fontSize: 14, padding: '8px 10px', backgroundColor: RADOM_COLORS.GRAY_MED, borderRadius: 5 }}
                key={f}>{f}</div>)
            }
          </div>
        }
        <Dropdown
          key={filters.join('')}
          containerStyle={{ width: 'fit-content' }}
          selectedContent={<div style={{ fontSize: 14 }}>Status</div>}
          dropdownContent={<div>
            {[OnrampStatusFilter.AwaitingFunds, OnrampStatusFilter.FundsReceived, OnrampStatusFilter.Complete]
              .map(s =>
                <DropdownItem
                  key={s}
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                  onClick={() => {
                    setCurPage(0)
                    if (filters.includes(s)) {
                      setFilters(filters.filter(a => a !== s))
                    } else {
                      setFilters([...filters, s])
                    }
                  }}>
                  <Checkbox size={15} checked={filters.includes(s)}/>
                  <span>{s}</span>
                </DropdownItem>
              )}
          </div>} />
      </div>

      <Table
        onClickRow={(i) => { window.open(`/onramp/${onramps[i].id}`, '_blank') }}
        borderless
        headers={['ID', 'Status', 'Amount', 'Asset', 'Completed at', 'Created at'].map(t => <span key={t}>{t}</span>)}
        items={onramps.map(r => [
          <div key="1">{r.id}</div>,
          <div key="2">
            <StatusBadge status={camelCase(r.status)}>{r.status}</StatusBadge>
          </div>,
          <div key="3">{
            formatCurrency(r.amount, r.fromCurrency)
          }</div>,
          <div key="4">
            <PaymentMethodDisplay method={{ network: r.toNetwork, token: r.toToken }} disableTooltip={false} />
          </div>,
          <div key="5">
            {
              r.completedAt
                ? <TimeText date={new Date(r.completedAt)} />
                : '-'
            }
          </div>,
          <div key="6">
            <TimeText date={new Date(r.createdAt)} />
          </div>
        ])}
        emptyPlaceholderText='No onramps'
        isLoading={isLoading} />

      <Pagination
        curPage={curPage}
        numPages={Math.ceil(totalSessions / pageSize)}
        onPageClick={page => setCurPage(page)}
      />
    </div>
  </Container>
})

export default Onramps
